<template>
  <form-field
    :hide="field.hide"
    :name="field.name"
  >
    <v-autocomplete
      v-model="field.value"
      :items="items"
      :menu-props="{maxHeight:'auto'}"
      combobox
      dense
      box
      hint="Select or type year of study"
      label="Enter year of study"
      persistent-hint
    />
  </form-field>
</template>

<script>
import formField from '@/mixins/form-field'

export default {
  mixins: [formField],
  data () {
    return {
      items: ['1', '2', '3', '4', '5', '6', '7'],
    }
  },
  // mounted: function () {
  //   this.field.props.rules || this.$set(this.field.props, 'rules', [val => !!val || 'Year of Study required!'])
  // },
}
</script>
